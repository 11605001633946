











.ds-footer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  color: #ddd;
}
