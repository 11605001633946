







































.ds-header {
  position: sticky;
  top: 0px;
  $height: 60px;
  height: $height;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  .ds-logo {
    padding: 5px;
    .ds-logo-img {
      width: $height - 10px;
    }
  }
  .ds-navs {
    margin: 0;
    padding: 0;
    height: 100%;
    width: fit-content;
    display: flex;
    .ds-nav {
      padding: 0 15px;
      color: #fff;
      list-style: none;
      line-height: $height;
      cursor: pointer;
    }
  }
}
